import { POST_ROLE_REQUEST, POST_ROLE_SUCCESS, POST_ROLE_FAILURE } from "./actionTypes";

const initialState = {
  buttonLoading: false,
};

const createRoleReducer = (state = initialState, { type }) => {
  switch (type) {
    case POST_ROLE_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      };
    case POST_ROLE_SUCCESS:
    case POST_ROLE_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };
    default:
      return state;
  }
};

export default createRoleReducer;
