import { GET_REPORT_FOR_DUTY_LOG_SUCCESS } from "./actionTypes";

const initialState = {
  reportForDutyLog: null,
};

const viewReportForDutyLogReducer = (state = initialState, { type, reportForDutyLog }) => {
  switch (type) {
    case GET_REPORT_FOR_DUTY_LOG_SUCCESS:
      return {
        ...state,
        reportForDutyLog,
      };
    default:
      return state;
  }
};

export default viewReportForDutyLogReducer;
