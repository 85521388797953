import {
  GET_WELNESS_ACKNOWLEDGEMENTS_REQUEST,
  GET_WELNESS_ACKNOWLEDGEMENTS_SUCCESS,
  GET_WELNESS_ACKNOWLEDGEMENTS_FAILURE,
  RESET_WELNESS_ACKNOWLEDGEMENTS,
  SET_WELNESS_ACKNOWLEDGEMENTS_META,
} from "./actionTypes";

const initialState = {
  wellnessAcknowledgements: [],
  tableLoading: false,
  meta: sessionStorage.getItem("wellnessAcknowledgementsMeta")
    ? JSON.parse(sessionStorage.getItem("wellnessAcknowledgementsMeta"))
    : {
        page: 1,
        perPage: 10,
        total: 10,
        sortBy: "id",
        direction: "asc",
        search: "",
      },
  forceFetch: false,
};

const wellnessAcknowledgementsReducer = (
  state = initialState,
  { type, wellnessAcknowledgements, meta },
) => {
  switch (type) {
    case GET_WELNESS_ACKNOWLEDGEMENTS_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_WELNESS_ACKNOWLEDGEMENTS_SUCCESS:
      return {
        ...state,
        wellnessAcknowledgements,
        tableLoading: false,
      };
    case GET_WELNESS_ACKNOWLEDGEMENTS_FAILURE:
      return { ...state, tableLoading: false };
    case SET_WELNESS_ACKNOWLEDGEMENTS_META:
      sessionStorage.setItem(
        "wellnessAcknowledgementsMeta",
        JSON.stringify({ ...state.meta, ...meta }),
      );
      return { ...state, meta: { ...state.meta, ...meta } };
    case RESET_WELNESS_ACKNOWLEDGEMENTS:
      return { ...initialState, meta: state.meta };
    default:
      return state;
  }
};

export default wellnessAcknowledgementsReducer;
