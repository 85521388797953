import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  PATCH_USER_REQUEST,
  PATCH_USER_SUCCESS,
  PATCH_USER_FAILURE,
  RESET_USER,
} from "./actionTypes";
import {
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  RESTORE_USER_REQUEST,
  RESTORE_USER_SUCCESS,
  RESTORE_USER_FAILURE,
} from "../List/actionTypes";

const initialState = {
  user: null,
  userLoading: true,
  buttonLoading: false,
  statusIsChanging: false,
};

const editUserReducer = (state = initialState, { type, user }) => {
  switch (type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        userLoading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        user,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        userLoading: false,
      };
    case PATCH_USER_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      };
    case PATCH_USER_SUCCESS:
    case PATCH_USER_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };
    case DELETE_USER_REQUEST:
    case RESTORE_USER_REQUEST:
      return { ...state, statusIsChanging: true };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        statusIsChanging: false,
      };
    case RESTORE_USER_SUCCESS:
      return {
        ...state,
        statusIsChanging: false,
      };
    case DELETE_USER_FAILURE:
    case RESTORE_USER_FAILURE:
      return { ...state, statusIsChanging: false };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
};

export default editUserReducer;
