import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Route, Switch } from "react-router";
import { CSpinner } from "@coreui/react-pro";
import PrivateRoute from './PrivateRoute';
import "../scss/style.scss";

// Containers
const AppLayout = React.lazy(() => import("../layout/AppLayout"));

// Pages
const Login = React.lazy(() => import("../features/Login"));
const Register = React.lazy(() => import("../features/Register"));
const Page404 = React.lazy(() => import("../layout/Page404"));

const AppRouter = () => (
  <BrowserRouter>
    <Suspense
      fallback={
        <div className="d-flex w-100 vh-100 align-items-center justify-content-center">
          <CSpinner color="primary" />
        </div>
      }
    >
      <Switch>
        <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
        {/* <Route
          exact
          path="/register"
          name="Register Page"
          render={(props) => <Register {...props} />}
        /> */}
        {/* <Route path="/404" name="Page 404" render={(props) => <Page404 {...props} />} /> */}
        <PrivateRoute path="/" name="Home" render={(props) => <AppLayout {...props} />} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default AppRouter;
