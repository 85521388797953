import { GET_FORM_B_ACTIVITY_SUCCESS } from "./actionTypes";

const initialState = {
  formBActivity: null,
};

const viewFormBActivityReducer = (state = initialState, { type, formBActivity }) => {
  switch (type) {
    case GET_FORM_B_ACTIVITY_SUCCESS:
      return {
        ...state,
        formBActivity,
      };
    default:
      return state;
  }
};

export default viewFormBActivityReducer;
