import { put, takeEvery, all, delay } from "redux-saga/effects";

import { handleNotifications } from "../features/Notifications/saga";

export default function* rootSaga() {
  yield all([handleSnack(), handleNotifications()]);
}

export function* openErrorSnack(e) {
  yield put({
    type: "ERROR_SNACK_OPEN",
    error:
      e && e.error && e.error.response && e.error.response.data
        ? typeof Object?.values(e.error.response.data)[0] === "string"
          ? Object?.values(e.error.response.data)[0]
          : Object?.values(e.error.response.data)[0][0]
        : typeof e.error === "string"
        ? e.error
        : "Something went wrong",
  });
}

export function* openSuccessSnack(success) {
  yield put({ type: "SUCCESS_SNACK_OPEN", success });
}

export function* resetErrorSnack() {
  yield delay(100);
  yield put({ type: "ERROR_SNACK_CLOSE" });
}

export function* resetSuccessSnack() {
  yield delay(100);
  yield put({ type: "SUCCESS_SNACK_CLOSE" });
}

export function* handleSnack() {
  yield takeEvery("ERROR_SNACK_OPEN", resetErrorSnack);
  yield takeEvery("SUCCESS_SNACK_OPEN", resetSuccessSnack);
}
