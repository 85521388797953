import { GET_EOD_REPORTS_LOG_SUCCESS } from "./actionTypes";

const initialState = {
  eodReportsLog: null,
};

const viewEODReportsLogReducer = (state = initialState, { type, eodReportsLog }) => {
  switch (type) {
    case GET_EOD_REPORTS_LOG_SUCCESS:
      return {
        ...state,
        eodReportsLog,
      };
    default:
      return state;
  }
};

export default viewEODReportsLogReducer;
