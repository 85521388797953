import { combineReducers } from "redux";

import callNotifications from "./CallNotifications/reducer";
import wellnessAcknowledgements from "./WellnessAcknowledgement/reducer";
import flagsActivities from "./FlagsActivity/reducer";
import formBsActivity from "./FormBsActivity/reducer";
import pushNotificationsLogs from "./PushNotificationsLogs/reducer";
import reportForDutyLogs from "./ReportForDutyLogs/reducer";
import eodReportsLogs from "./EODReportsLogs/reducer";
import jobBriefings from "./JobBriefings/reducer";

const logsReducer = combineReducers({
  callNotifications,
  wellnessAcknowledgements,
  flagsActivities,
  formBsActivity,
  pushNotificationsLogs,
  reportForDutyLogs,
  eodReportsLogs,
  jobBriefings,
});

export default logsReducer;
