export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const RESTORE_USER_REQUEST = "RESTORE_USER_REQUEST";
export const RESTORE_USER_SUCCESS = "RESTORE_USER_SUCCESS";
export const RESTORE_USER_FAILURE = "RESTORE_USER_FAILURE";
