export const GET_JOB_BRIEFINGS_CONSENTS_REQUEST = "GET_JOB_BRIEFINGS_CONSENTS_REQUEST";
export const GET_JOB_BRIEFINGS_CONSENTS_SUCCESS = "GET_JOB_BRIEFINGS_CONSENTS_SUCCESS";
export const GET_JOB_BRIEFINGS_CONSENTS_FAILURE = "GET_JOB_BRIEFINGS_CONSENTS_FAILURE";

export const DELETE_JOB_BRIEFINGS_CONSENT_REQUEST = "DELETE_JOB_BRIEFINGS_CONSENT_REQUEST";
export const DELETE_JOB_BRIEFINGS_CONSENT_SUCCESS = "DELETE_JOB_BRIEFINGS_CONSENT_SUCCESS";
export const DELETE_JOB_BRIEFINGS_CONSENT_FAILURE = "DELETE_JOB_BRIEFINGS_CONSENT_FAILURE";

export const SET_JOB_BRIEFINGS_CONSENTS_META = "SET_JOB_BRIEFINGS_CONSENTS_META";
export const RESET_JOB_BRIEFINGS_CONSENTS = "RESET_JOB_BRIEFINGS_CONSENTS";
