import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  AUTH_SET_LOADING,
} from "./actionTypes";

const initialState = {
  me: null,
  access_token: null,
  isLoading: true,
};

const authReducer = (state = initialState, { type, data, isLoading }) => {
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS: {
      localStorage.setItem("rt", data.refresh_token);
      return {
        ...state,
        me: data.user,
        access_token: data.access_token,
        isLoading: false,
      };
    }
    case LOGIN_FAILURE:
      return { ...state, isLoading: false };
    case LOGOUT:
      localStorage.removeItem("rt");
      return {
        ...initialState,
        refresh_token: null,
      };
    case AUTH_SET_LOADING:
      return {
        ...state,
        isLoading,
      };
    default:
      return state;
  }
};

export default authReducer;
