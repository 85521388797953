import { takeEvery } from "redux-saga/effects";
import { openSuccessSnack, openErrorSnack } from "../../core/rootSaga";

export function* handleNotifications() {
  yield takeEvery("DISPATCH_SUCCESS_SNACK", ({ success }) => openSuccessSnack(success));
  yield takeEvery("DISPATCH_ERROR_SNACK", openErrorSnack);

  // notifications for Users
  yield takeEvery("DELETE_USER_SUCCESS", () => openSuccessSnack("User has been deleted"));
  yield takeEvery("RESTORE_USER_SUCCESS", () => openSuccessSnack("User has been restored"));
  yield takeEvery("POST_USER_SUCCESS", () => openSuccessSnack("User has been created"));
  yield takeEvery("PATCH_USER_SUCCESS", () => openSuccessSnack("User has been updated"));
  yield takeEvery("DELETE_USER_FAILURE", openErrorSnack);
  yield takeEvery("RESTORE_USER_FAILURE", openErrorSnack);
  yield takeEvery("GET_USERS_FAILURE", openErrorSnack);
  yield takeEvery("GET_USER_FAILURE", openErrorSnack);

  // notifications for Properties
  yield takeEvery("DELETE_PROPERTY_SUCCESS", () => openSuccessSnack("Property has been deleted"));
  yield takeEvery("RESTORE_PROPERTY_SUCCESS", () => openSuccessSnack("Property has been restored"));
  yield takeEvery("POST_PROPERTY_SUCCESS", () => openSuccessSnack("Property has been created"));
  yield takeEvery("PATCH_PROPERTY_SUCCESS", () => openSuccessSnack("Property has been updated"));
  yield takeEvery("DELETE_PROPERTY_FAILURE", openErrorSnack);
  yield takeEvery("RESTORE_PROPERTY_FAILURE", openErrorSnack);
  yield takeEvery("GET_PROPERTIES_FAILURE", openErrorSnack);
  yield takeEvery("GET_PROPERTY_FAILURE", openErrorSnack);

  // notifications for Roles
  yield takeEvery("DELETE_ROLE_SUCCESS", () => openSuccessSnack("Role has been deleted"));
  yield takeEvery("RESTORE_ROLE_SUCCESS", () => openSuccessSnack("Role has been restored"));
  yield takeEvery("POST_ROLE_SUCCESS", () => openSuccessSnack("Role has been created"));
  yield takeEvery("PATCH_ROLE_SUCCESS", () => openSuccessSnack("Role has been updated"));
  yield takeEvery("DELETE_ROLE_FAILURE", openErrorSnack);
  yield takeEvery("RESTORE_ROLE_FAILURE", openErrorSnack);
  yield takeEvery("GET_ROLES_FAILURE", openErrorSnack);
  yield takeEvery("GET_ROLE_FAILURE", openErrorSnack);

  // notifications for Form Bs
  yield takeEvery("GET_FORM_BS_FAILURE", openErrorSnack);
  yield takeEvery("GET_FORM_B_FAILURE", openErrorSnack);

  // notifications for Flags
  yield takeEvery("GET_FLAGS_FAILURE", openErrorSnack);
  yield takeEvery("GET_FLAGS_FAILURE", openErrorSnack);

  // notifications for Jobs
  yield takeEvery("DELETE_JOB_SUCCESS", () => openSuccessSnack("Job has been deleted"));
  yield takeEvery("POST_JOB_SUCCESS", () => openSuccessSnack("Job has been created"));
  yield takeEvery("PATCH_JOB_SUCCESS", () => openSuccessSnack("Job has been updated"));
  yield takeEvery("DELETE_JOB_FAILURE", openErrorSnack);
  yield takeEvery("GET_JOBS_FAILURE", openErrorSnack);
  yield takeEvery("GET_JOB_FAILURE", openErrorSnack);

  // notifications for Job Briefings Consents
  yield takeEvery("DELETE_JOB_BRIEFINGS_CONSENT_SUCCESS", () =>
    openSuccessSnack("Job briefings consent has been deleted"),
  );
  yield takeEvery("POST_JOB_BRIEFINGS_CONSENT_SUCCESS", () =>
    openSuccessSnack("Job briefings consent has been created"),
  );
  yield takeEvery("PATCH_JOB_BRIEFINGS_CONSENT_SUCCESS", () =>
    openSuccessSnack("Job briefings consent has been updated"),
  );
  yield takeEvery("DELETE_JOB_BRIEFINGS_CONSENT_FAILURE", openErrorSnack);
  yield takeEvery("GET_JOB_BRIEFINGS_CONSENTS_FAILURE", openErrorSnack);
  yield takeEvery("GET_JOB_BRIEFINGS_CONSENT_FAILURE", openErrorSnack);

  // notifications for Call Notifications Logs
  yield takeEvery("GET_CALL_NOTIFICATION_FAILURE", openErrorSnack);
  yield takeEvery("GET_CALL_NOTIFICATIONS_FAILURE", openErrorSnack);

  // notifications for Acknowledgement Call Notifications Logs
  yield takeEvery("GET_ACKNOWLEDGEMENT_CALL_NOTIFICATION_FAILURE", openErrorSnack);
  yield takeEvery("GET_ACKNOWLEDGEMENT_CALL_NOTIFICATIONS_FAILURE", openErrorSnack);

  // notifications for Wellness Acknowledgements
  yield takeEvery("GET_WELNESS_ACKNOWLEDGEMENT_FAILURE", openErrorSnack);
  yield takeEvery("GET_WELNESS_ACKNOWLEDGEMENTS_FAILURE", openErrorSnack);
}
