import {
  GET_CALL_NOTIFICATIONS_REQUEST,
  GET_CALL_NOTIFICATIONS_SUCCESS,
  GET_CALL_NOTIFICATIONS_FAILURE,
} from "./actionTypes";

const initialState = {
  callNotifications: [],
  tableLoading: false,
  forceFetch: false,
};

const callNotificationsReducer = (state = initialState, { type, callNotifications, meta }) => {
  switch (type) {
    case GET_CALL_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_CALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        callNotifications,
        tableLoading: false,
      };
    case GET_CALL_NOTIFICATIONS_FAILURE:
      return { ...state, tableLoading: false };
    default:
      return state;
  }
};

export default callNotificationsReducer;
