import { combineReducers } from "redux";

import list from "./List/reducer";
import create from "./Create/reducer";
import edit from "./Edit/reducer";
import form from "./_shared/JobBriefingsConsentsForm/reducer";

const jobBriefingsConsentsReducer = combineReducers({
  list,
  create,
  edit,
  form,
});

export default jobBriefingsConsentsReducer;
