import {
  GET_PROPERTY_REQUEST,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY_FAILURE,
  PATCH_PROPERTY_REQUEST,
  PATCH_PROPERTY_SUCCESS,
  PATCH_PROPERTY_FAILURE,
} from "./actionTypes";
import {
  DELETE_PROPERTY_FAILURE,
  DELETE_PROPERTY_REQUEST,
  DELETE_PROPERTY_SUCCESS,
  RESTORE_PROPERTY_REQUEST,
  RESTORE_PROPERTY_SUCCESS,
  RESTORE_PROPERTY_FAILURE,
} from "../List/actionTypes";

const initialState = {
  property: null,
  propertyLoading: true,
  buttonLoading: false,
  statusIsChanging: false,
};

const editPropertyReducer = (state = initialState, { type, property }) => {
  switch (type) {
    case GET_PROPERTY_REQUEST:
      return {
        ...state,
        propertyLoading: true,
      };
    case GET_PROPERTY_SUCCESS:
      return {
        ...state,
        propertyLoading: false,
        property,
      };
    case GET_PROPERTY_FAILURE:
      return {
        ...state,
        propertyLoading: false,
      };
    case PATCH_PROPERTY_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      };
    case PATCH_PROPERTY_SUCCESS:
    case PATCH_PROPERTY_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };
    case DELETE_PROPERTY_REQUEST:
    case RESTORE_PROPERTY_REQUEST:
      return { ...state, statusIsChanging: true };
    case DELETE_PROPERTY_SUCCESS:
      case RESTORE_PROPERTY_SUCCESS:
      return {
        ...state,
        statusIsChanging: false,
      };
    case DELETE_PROPERTY_FAILURE:
    case RESTORE_PROPERTY_FAILURE:
      return { ...state, statusIsChanging: false };
    default:
      return state;
  }
};

export default editPropertyReducer;
