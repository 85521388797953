import {
  GET_FLAGS_ACTIVITIES_REQUEST,
  GET_FLAGS_ACTIVITIES_SUCCESS,
  GET_FLAGS_ACTIVITIES_FAILURE,
} from "./actionTypes";

const initialState = {
  flagsActivities: [],
  tableLoading: false,
  forceFetch: false,
};

const flagsActivitiesReducer = (state = initialState, { type, flagsActivities, meta }) => {
  switch (type) {
    case GET_FLAGS_ACTIVITIES_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_FLAGS_ACTIVITIES_SUCCESS:
      return {
        ...state,
        flagsActivities,
        tableLoading: false,
      };
    case GET_FLAGS_ACTIVITIES_FAILURE:
      return { ...state, tableLoading: false };
    default:
      return state;
  }
};

export default flagsActivitiesReducer;
