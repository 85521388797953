import { POST_USER_REQUEST, POST_USER_SUCCESS, POST_USER_FAILURE } from "./actionTypes";

const initialState = {
  buttonLoading: false,
};

const createUserReducer = (state = initialState, { type }) => {
  switch (type) {
    case POST_USER_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      };
    case POST_USER_SUCCESS:
    case POST_USER_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };
    default:
      return state;
  }
};

export default createUserReducer;
