import {
  GET_REPORT_FOR_DUTY_LOGS_REQUEST,
  GET_REPORT_FOR_DUTY_LOGS_SUCCESS,
  GET_REPORT_FOR_DUTY_LOGS_FAILURE,
} from "./actionTypes";

const initialState = {
  reportForDutyLogs: [],
  tableLoading: false,
  forceFetch: false,
};

const reportForDutyLogsReducer = (state = initialState, { type, reportForDutyLogs, meta }) => {
  switch (type) {
    case GET_REPORT_FOR_DUTY_LOGS_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_REPORT_FOR_DUTY_LOGS_SUCCESS:
      return {
        ...state,
        reportForDutyLogs,
        tableLoading: false,
      };
    case GET_REPORT_FOR_DUTY_LOGS_FAILURE:
      return { ...state, tableLoading: false };
    default:
      return state;
  }
};

export default reportForDutyLogsReducer;
