import { GET_JOB_BRIEFINGS_FOR_SELECTOR_SUCCESS } from "./actionTypes";

const initialState = {
  jobBriefingsForSelector: [],
};

const jobBriefingsConsentFormReducer = (
  state = initialState,
  { type, jobBriefingsForSelector },
) => {
  switch (type) {
    case GET_JOB_BRIEFINGS_FOR_SELECTOR_SUCCESS:
      return {
        ...state,
        jobBriefingsForSelector,
      };
    default:
      return state;
  }
};

export default jobBriefingsConsentFormReducer;
