import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  RESTORE_USER_SUCCESS,
  RESTORE_USER_REQUEST,
  RESTORE_USER_FAILURE,
} from "./actionTypes";

const initialState = {
  users: [],
  tableLoading: false,
  buttonLoading: false,
  forceFetch: false,
};

const usersReducer = (state = initialState, { type, users, meta }) => {
  switch (type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users,
        tableLoading: false,
      };
    case GET_USERS_FAILURE:
      return { ...state, tableLoading: false };
    case DELETE_USER_REQUEST:
    case RESTORE_USER_REQUEST:
      return { ...state, buttonLoading: true };
    case DELETE_USER_SUCCESS:
    case RESTORE_USER_SUCCESS:
      return {
        ...state,
        forceFetch: true,
        buttonLoading: false,
      };
    case DELETE_USER_FAILURE:
    case RESTORE_USER_FAILURE:
      return { ...state, buttonLoading: false };
    default:
      return state;
  }
};

export default usersReducer;
