import { combineReducers } from "redux";

import list from "./List/reducer";
import create from "./Create/reducer";
import edit from "./Edit/reducer";

const propertiesReducer = combineReducers({
  list,
  create,
  edit,
});

export default propertiesReducer;
