import { GET_FORM_B_SUCCESS } from "./actionTypes";

const initialState = {
  formB: null,
};

const viewFormBReducer = (state = initialState, { type, formB }) => {
  switch (type) {
    case GET_FORM_B_SUCCESS:
      return {
        ...state,
        formB,
      };
    default:
      return state;
  }
};

export default viewFormBReducer;
