import {
  GET_FORM_BS_REQUEST,
  GET_FORM_BS_SUCCESS,
  GET_FORM_BS_FAILURE,
} from "./actionTypes";

const initialState = {
  formBs: [],
  tableLoading: false,
  forceFetch: false,
};

const formBsReducer = (state = initialState, { type, formBs }) => {
  switch (type) {
    case GET_FORM_BS_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_FORM_BS_SUCCESS:
      return {
        ...state,
        formBs,
        tableLoading: false,
      };
    case GET_FORM_BS_FAILURE:
      return { ...state, tableLoading: false };
    default:
      return state;
  }
};

export default formBsReducer;
