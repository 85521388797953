import {
  GET_FLAGS_REQUEST,
  GET_FLAGS_SUCCESS,
  GET_FLAGS_FAILURE,
} from "./actionTypes";

const initialState = {
  flags: [],
  tableLoading: false,
  forceFetch: false,
};

const flagsReducer = (state = initialState, { type, flags, meta }) => {
  switch (type) {
    case GET_FLAGS_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_FLAGS_SUCCESS:
      return {
        ...state,
        flags,
        tableLoading: false,
      };
    case GET_FLAGS_FAILURE:
      return { ...state, tableLoading: false };
    default:
      return state;
  }
};

export default flagsReducer;
