import {
  GET_PUSH_NOTIFICATIONS_LOGS_REQUEST,
  GET_PUSH_NOTIFICATIONS_LOGS_SUCCESS,
  GET_PUSH_NOTIFICATIONS_LOGS_FAILURE,
} from "./actionTypes";

const initialState = {
  pushNotificationsLogs: [],
  tableLoading: false,
  forceFetch: false,
};

const pushNotificationsLogsReducer = (
  state = initialState,
  { type, pushNotificationsLogs, meta },
) => {
  switch (type) {
    case GET_PUSH_NOTIFICATIONS_LOGS_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_PUSH_NOTIFICATIONS_LOGS_SUCCESS:
      return {
        ...state,
        pushNotificationsLogs,
        tableLoading: false,
      };
    case GET_PUSH_NOTIFICATIONS_LOGS_FAILURE:
      return { ...state, tableLoading: false };
    default:
      return state;
  }
};

export default pushNotificationsLogsReducer;
