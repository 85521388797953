import {
  GET_ROLE_REQUEST,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILURE,
  PATCH_ROLE_REQUEST,
  PATCH_ROLE_SUCCESS,
  PATCH_ROLE_FAILURE,
} from "./actionTypes";
import { DELETE_ROLE_FAILURE, DELETE_ROLE_REQUEST, DELETE_ROLE_SUCCESS } from "../List/actionTypes";

const initialState = {
  role: null,
  roleLoading: true,
  buttonLoading: false,
  statusIsChanging: false,
};

const editRoleReducer = (state = initialState, { type, role }) => {
  switch (type) {
    case GET_ROLE_REQUEST:
      return {
        ...state,
        roleLoading: true,
      };
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        roleLoading: false,
        role,
      };
    case GET_ROLE_FAILURE:
      return {
        ...state,
        roleLoading: false,
      };
    case PATCH_ROLE_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      };
    case PATCH_ROLE_SUCCESS:
    case PATCH_ROLE_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };
    case DELETE_ROLE_REQUEST:
      return { ...state, statusIsChanging: true };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        statusIsChanging: false,
      };
    case DELETE_ROLE_FAILURE:
      return { ...state, statusIsChanging: false };
    default:
      return state;
  }
};

export default editRoleReducer;
