import { GET_CALL_NOTIFICATION_SUCCESS } from "./actionTypes";

const initialState = {
  callNotification: null,
};

const viewCallNotificationReducer = (state = initialState, { type, callNotification }) => {
  switch (type) {
    case GET_CALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        callNotification,
      };
    default:
      return state;
  }
};

export default viewCallNotificationReducer;
