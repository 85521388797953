import {
  GET_FORM_BS_ACTIVITY_REQUEST,
  GET_FORM_BS_ACTIVITY_SUCCESS,
  GET_FORM_BS_ACTIVITY_FAILURE,
} from "./actionTypes";

const initialState = {
  formBsActivity: [],
  tableLoading: false,
  forceFetch: false,
};

const formBsActivityReducer = (state = initialState, { type, formBsActivity, meta }) => {
  switch (type) {
    case GET_FORM_BS_ACTIVITY_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_FORM_BS_ACTIVITY_SUCCESS:
      return {
        ...state,
        formBsActivity,
        tableLoading: false,
      };
    case GET_FORM_BS_ACTIVITY_FAILURE:
      return { ...state, tableLoading: false };
    default:
      return state;
  }
};

export default formBsActivityReducer;
