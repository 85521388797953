import {
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
} from "./actionTypes";

const initialState = {
  roles: [],
  tableLoading: false,
  buttonLoading: false,
  forceFetch: false,
};

const rolesReducer = (state = initialState, { type, roles, meta }) => {
  switch (type) {
    case GET_ROLES_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles,
        tableLoading: false,
      };
    case GET_ROLES_FAILURE:
      return { ...state, tableLoading: false };
    case DELETE_ROLE_REQUEST:
      return { ...state, buttonLoading: true };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        forceFetch: true,
        buttonLoading: false,
      };
    case DELETE_ROLE_FAILURE:
      return { ...state, buttonLoading: false };
    default:
      return state;
  }
};

export default rolesReducer;
