import { combineReducers } from "redux";

import usersReducer from "../features/Users/reducer";
import propertiesReducer from "../features/Properties/reducer";
import rolesReducer from "../features/Roles/reducer";

import formBsReducer from "../features/FormBs/reducer";
import flagsReducer from "../features/Flags/reducer";
import jobBriefingsConsentsReducer from "../features/JobBriefingsConsents/reducer";

import logsReducer from "../features/Logs/reducer";
import authReducer from '../features/Login/reducer';

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  theme: localStorage.getItem("theme") || "default",
  errorSnack: false,
  errorSnackText: "",
  successSnack: "",
  successSnackText: "",
};

const baseReducer = (
  state = initialState,
  { type, roles, initiativeId, error, success, ...rest },
) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "ERROR_SNACK_OPEN":
      return { ...state, errorSnack: true, errorSnackText: error };
    case "ERROR_SNACK_CLOSE":
      return { ...state, errorSnack: false };
    case "SUCCESS_SNACK_OPEN":
      return { ...state, successSnack: true, successSnackText: success };
    case "SUCCESS_SNACK_CLOSE":
      return { ...state, successSnack: false };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  base: baseReducer,

  users: usersReducer,
  properties: propertiesReducer,
  roles: rolesReducer,

  formBs: formBsReducer,
  flags: flagsReducer,
  jobBriefingsConsents: jobBriefingsConsentsReducer,

  logs: logsReducer,
  auth: authReducer,
});

export default rootReducer;
