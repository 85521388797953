import {
  GET_JOB_BRIEFINGS_REQUEST,
  GET_JOB_BRIEFINGS_SUCCESS,
  GET_JOB_BRIEFINGS_FAILURE,
  RESET_JOB_BRIEFINGS,
  SET_JOB_BRIEFINGS_META,
} from "./actionTypes";

const initialState = {
  jobBriefings: [],
  tableLoading: false,
  meta: sessionStorage.getItem("jobBriefingsMeta")
    ? JSON.parse(sessionStorage.getItem("jobBriefingsMeta"))
    : {
        page: 1,
        perPage: 10,
        total: 10,
        sortBy: "id",
        direction: "asc",
        search: "",
      },
  forceFetch: false,
};

const jobBriefingsReducer = (state = initialState, { type, jobBriefings, meta }) => {
  switch (type) {
    case GET_JOB_BRIEFINGS_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_JOB_BRIEFINGS_SUCCESS:
      return {
        ...state,
        jobBriefings,
        tableLoading: false,
      };
    case GET_JOB_BRIEFINGS_FAILURE:
      return { ...state, tableLoading: false };
    case SET_JOB_BRIEFINGS_META:
      sessionStorage.setItem("jobBriefingsMeta", JSON.stringify({ ...state.meta, ...meta }));
      return { ...state, meta: { ...state.meta, ...meta } };
    case RESET_JOB_BRIEFINGS:
      return { ...initialState, meta: state.meta };
    default:
      return state;
  }
};

export default jobBriefingsReducer;
