import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const PrivateRoute = ({ render, ...rest }) => {
  const currentUser = useSelector(({ auth }) => auth.me);
  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser ? render(props) : (
          <Redirect
            to={{
              pathname: "/login",
              // eslint-disable-next-line react/prop-types
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  render: PropTypes.func.isRequired,
};

export default PrivateRoute;
