import {
  GET_JOB_BRIEFINGS_CONSENTS_REQUEST,
  GET_JOB_BRIEFINGS_CONSENTS_SUCCESS,
  GET_JOB_BRIEFINGS_CONSENTS_FAILURE,
  DELETE_JOB_BRIEFINGS_CONSENT_REQUEST,
  DELETE_JOB_BRIEFINGS_CONSENT_SUCCESS,
  DELETE_JOB_BRIEFINGS_CONSENT_FAILURE,
  RESET_JOB_BRIEFINGS_CONSENTS,
  SET_JOB_BRIEFINGS_CONSENTS_META,
} from "./actionTypes";

const initialState = {
  jobBriefingsConsents: [],
  tableLoading: false,
  buttonLoading: false,
  meta: sessionStorage.getItem("jobBriefingsConsentsMeta")
    ? JSON.parse(sessionStorage.getItem("jobBriefingsConsentsMeta"))
    : {
        page: 1,
        perPage: 10,
        total: 10,
        sortBy: "id",
        direction: "asc",
        search: "",
      },
  forceFetch: false,
};

const jobBriefingsConsentsReducer = (
  state = initialState,
  { type, jobBriefingsConsents, meta },
) => {
  switch (type) {
    case GET_JOB_BRIEFINGS_CONSENTS_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_JOB_BRIEFINGS_CONSENTS_SUCCESS:
      return {
        ...state,
        jobBriefingsConsents,
        tableLoading: false,
      };
    case GET_JOB_BRIEFINGS_CONSENTS_FAILURE:
      return { ...state, tableLoading: false };
    case DELETE_JOB_BRIEFINGS_CONSENT_REQUEST:
      return { ...state, buttonLoading: true };
    case DELETE_JOB_BRIEFINGS_CONSENT_SUCCESS:
      return {
        ...state,
        forceFetch: true,
        buttonLoading: false,
      };
    case DELETE_JOB_BRIEFINGS_CONSENT_FAILURE:
      return { ...state, buttonLoading: false };
    case SET_JOB_BRIEFINGS_CONSENTS_META:
      sessionStorage.setItem(
        "jobBriefingsConsentsMeta",
        JSON.stringify({ ...state.meta, ...meta }),
      );
      return { ...state, meta: { ...state.meta, ...meta } };
    case RESET_JOB_BRIEFINGS_CONSENTS:
      return { ...initialState, meta: state.meta };
    default:
      return state;
  }
};

export default jobBriefingsConsentsReducer;
