import { GET_PUSH_NOTIFICATIONS_LOG_SUCCESS } from "./actionTypes";

const initialState = {
  pushNotificationsLog: null,
};

const viewPushNotificationsLogReducer = (state = initialState, { type, pushNotificationsLog }) => {
  switch (type) {
    case GET_PUSH_NOTIFICATIONS_LOG_SUCCESS:
      return {
        ...state,
        pushNotificationsLog,
      };
    default:
      return state;
  }
};

export default viewPushNotificationsLogReducer;
