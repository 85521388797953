import {
  GET_JOB_BRIEFINGS_CONSENT_REQUEST,
  GET_JOB_BRIEFINGS_CONSENT_SUCCESS,
  GET_JOB_BRIEFINGS_CONSENT_FAILURE,
  PATCH_JOB_BRIEFINGS_CONSENT_REQUEST,
  PATCH_JOB_BRIEFINGS_CONSENT_SUCCESS,
  PATCH_JOB_BRIEFINGS_CONSENT_FAILURE,
  RESET_JOB_BRIEFINGS_CONSENT,
} from "./actionTypes";
import {
  DELETE_JOB_BRIEFINGS_CONSENT_FAILURE,
  DELETE_JOB_BRIEFINGS_CONSENT_REQUEST,
  DELETE_JOB_BRIEFINGS_CONSENT_SUCCESS,
} from "../List/actionTypes";

const initialState = {
  jobBriefingsConsent: {
    jobBriefingsConsentNumber: "",
  },
  jobBriefingsConsentLoading: true,
  buttonLoading: false,
  statusIsChanging: false,
};

const editJobBriefingsConsentReducer = (state = initialState, { type, jobBriefingsConsent }) => {
  switch (type) {
    case GET_JOB_BRIEFINGS_CONSENT_REQUEST:
      return {
        ...state,
        jobBriefingsConsentLoading: true,
      };
    case GET_JOB_BRIEFINGS_CONSENT_SUCCESS:
      return {
        ...state,
        jobBriefingsConsentLoading: false,
        jobBriefingsConsent,
      };
    case GET_JOB_BRIEFINGS_CONSENT_FAILURE:
      return {
        ...state,
        jobBriefingsConsentLoading: false,
      };
    case PATCH_JOB_BRIEFINGS_CONSENT_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      };
    case PATCH_JOB_BRIEFINGS_CONSENT_SUCCESS:
    case PATCH_JOB_BRIEFINGS_CONSENT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };
    case DELETE_JOB_BRIEFINGS_CONSENT_REQUEST:
      return { ...state, statusIsChanging: true };
    case DELETE_JOB_BRIEFINGS_CONSENT_SUCCESS:
      return {
        ...state,
        statusIsChanging: false,
      };
    case DELETE_JOB_BRIEFINGS_CONSENT_FAILURE:
      return { ...state, statusIsChanging: false };
    case RESET_JOB_BRIEFINGS_CONSENT:
      return initialState;
    default:
      return state;
  }
};

export default editJobBriefingsConsentReducer;
