import { GET_JOB_BRIEFING_SUCCESS, RESET_JOB_BRIEFING } from "./actionTypes";

const initialState = {
  jobBriefing: { initial: true },
};

const viewJobBriefingReducer = (state = initialState, { type, jobBriefing }) => {
  switch (type) {
    case GET_JOB_BRIEFING_SUCCESS:
      return {
        ...state,
        jobBriefing,
      };
    case RESET_JOB_BRIEFING:
      return initialState;
    default:
      return state;
  }
};

export default viewJobBriefingReducer;
