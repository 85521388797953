export const GET_PROPERTIES_REQUEST = "GET_PROPERTIES_REQUEST";
export const GET_PROPERTIES_SUCCESS = "GET_PROPERTIES_SUCCESS";
export const GET_PROPERTIES_FAILURE = "GET_PROPERTIES_FAILURE";

export const DELETE_PROPERTY_REQUEST = "DELETE_PROPERTY_REQUEST";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_FAILURE = "DELETE_PROPERTY_FAILURE";

export const RESTORE_PROPERTY_REQUEST = "RESTORE_PROPERTY_REQUEST";
export const RESTORE_PROPERTY_SUCCESS = "RESTORE_PROPERTY_SUCCESS";
export const RESTORE_PROPERTY_FAILURE = "RESTORE_PROPERTY_FAILURE";
