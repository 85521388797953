import { GET_WELNESS_ACKNOWLEDGEMENT_SUCCESS, RESET_WELNESS_ACKNOWLEDGEMENT } from "./actionTypes";

const initialState = {
  wellnessAcknowledgement: { initial: true },
};

const viewWellnessAcknowledgementReducer = (
  state = initialState,
  { type, wellnessAcknowledgement },
) => {
  switch (type) {
    case GET_WELNESS_ACKNOWLEDGEMENT_SUCCESS:
      return {
        ...state,
        wellnessAcknowledgement,
      };
    case RESET_WELNESS_ACKNOWLEDGEMENT:
      return initialState;
    default:
      return state;
  }
};

export default viewWellnessAcknowledgementReducer;
