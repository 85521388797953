import { combineReducers } from "redux";

import list from "./List/reducer";
import view from "./View/reducer";

const pushNotificationsLogsReducer = combineReducers({
  list,
  view,
});

export default pushNotificationsLogsReducer;
