import {
  GET_PROPERTIES_REQUEST,
  GET_PROPERTIES_SUCCESS,
  GET_PROPERTIES_FAILURE,
  DELETE_PROPERTY_REQUEST,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAILURE,
  RESTORE_PROPERTY_SUCCESS,
  RESTORE_PROPERTY_REQUEST,
  RESTORE_PROPERTY_FAILURE,
} from "./actionTypes";

const initialState = {
  properties: [],
  tableLoading: false,
  buttonLoading: false,
  forceFetch: false,
};

const propertiesReducer = (state = initialState, { type, properties, meta }) => {
  switch (type) {
    case GET_PROPERTIES_REQUEST:
      return {
        ...state,
        tableLoading: true,
        forceFetch: false,
      };
    case GET_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties,
        tableLoading: false,
      };
    case GET_PROPERTIES_FAILURE:
      return { ...state, tableLoading: false };
    case DELETE_PROPERTY_REQUEST:
    case RESTORE_PROPERTY_REQUEST:
      return { ...state, buttonLoading: true };
    case DELETE_PROPERTY_SUCCESS:
    case RESTORE_PROPERTY_SUCCESS:
      return {
        ...state,
        forceFetch: true,
        buttonLoading: false,
      };
    case DELETE_PROPERTY_FAILURE:
    case RESTORE_PROPERTY_FAILURE:
      return { ...state, buttonLoading: false };
    default:
      return state;
  }
};

export default propertiesReducer;
