import { GET_FLAG_SUCCESS } from "./actionTypes";

const initialState = {
  flag: null,
};

const viewFlagReducer = (state = initialState, { type, flag }) => {
  switch (type) {
    case GET_FLAG_SUCCESS:
      return {
        ...state,
        flag,
      };
    default:
      return state;
  }
};

export default viewFlagReducer;
