import { POST_PROPERTY_REQUEST, POST_PROPERTY_SUCCESS, POST_PROPERTY_FAILURE } from "./actionTypes";

const initialState = {
  buttonLoading: false,
};

const createPropertyReducer = (state = initialState, { type }) => {
  switch (type) {
    case POST_PROPERTY_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      };
    case POST_PROPERTY_SUCCESS:
    case POST_PROPERTY_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };
    default:
      return state;
  }
};

export default createPropertyReducer;
