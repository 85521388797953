import {
  POST_JOB_BRIEFINGS_CONSENT_REQUEST,
  POST_JOB_BRIEFINGS_CONSENT_SUCCESS,
  POST_JOB_BRIEFINGS_CONSENT_FAILURE,
} from "./actionTypes";

const initialState = {
  buttonLoading: false,
};

const createJobBriefingsConsentReducer = (state = initialState, { type }) => {
  switch (type) {
    case POST_JOB_BRIEFINGS_CONSENT_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      };
    case POST_JOB_BRIEFINGS_CONSENT_SUCCESS:
    case POST_JOB_BRIEFINGS_CONSENT_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };
    default:
      return state;
  }
};

export default createJobBriefingsConsentReducer;
